import { ComponentType } from 'react';

export type FieldsType = Record<string, FormFields>;

export enum FormFieldEnum {
  text = 'text',
  textarea = 'textarea',
  email = 'email',
  file = 'file',
  number = 'number',
  select = 'select',
  customComponent = 'customComponent',
  password = 'password',
}

export interface FormFields {
  name?: string;
  email?: string;
  subject?: string;
  message?: string;
  live?: string;
  file?: string;
  password?: string;
}

export interface FormParams {
  className?: string;
  placeholder?: string;
  componentProps?: Record<string, unknown>;
  component?: ComponentType<any>;
  as?: string;
  type?: FormFieldEnum;
  defaultActive?: { id: number; title: string } | null;
}
