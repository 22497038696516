import React, { ChangeEvent, useState } from 'react';

import cn from 'classnames';
import { Field, FieldInputProps, Form, Formik } from 'formik';
import { useTranslations } from 'next-intl';
import * as Yup from 'yup';

import formatBytes from '@/src/utils/formatBytes';

import { FormFieldEnum, FormFields, FormParams } from './config';

import Button from '../Button';

import styles from './FormConstructor.module.scss';

export interface FormConstructorData {
  fields: Record<string, FormParams>;
  validationSchema?: Yup.ObjectSchema<any>;
  onSubmit: {
    action: (values: any) => Promise<{ ok: boolean; data?: unknown }>;
    text: string;
    className?: string;
  };
  ExtraElement?: () => JSX.Element;
  formFieldsClassName?: string;
}

const FormConstructor = ({
  data,
  loading = false,
}: {
  data: FormConstructorData;
  loading?: boolean;
}) => {
  const {
    fields,
    validationSchema,
    onSubmit: { action, text, className: buttonClassName },
    ExtraElement,
    formFieldsClassName,
  } = data;
  const t = useTranslations();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const initialValues: Record<string, string> = Object.keys(fields).reduce(
    (prev, current) => ({
      ...prev,
      [current]: fields[current]?.defaultActive ? fields[current]?.defaultActive?.id : '',
    }),
    {},
  );

  const onFormSubmit = (
    values: Record<string, string | File>,
    { resetForm }: { resetForm: VoidFunction },
  ) => {
    action(values).then(res => {
      if (res?.ok) {
        resetForm();
        setUploadedFile(null);
      }
    });
  };

  const sliceTextLength = (text: string) => (text.length > 20 ? `${text.slice(0, 20)}...` : text);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      {({ handleSubmit, setFieldValue, errors, touched, values }) => (
        <Form
          className={styles.form}
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className={cn(styles.form__fields_wrapper, formFieldsClassName)}>
            {Object.entries(fields).map(
              ([name, { placeholder, type, className, component, componentProps }]) => {
                const isTextArea = type === FormFieldEnum.textarea;
                const isFileUpload = type === FormFieldEnum.file;
                const isCustomField = type === FormFieldEnum.customComponent || component;
                const fieldProps: Partial<FieldInputProps<FormFields> & FormParams> = {
                  name,
                  className: cn(styles.form__text_field, className, {
                    [styles.form__textarea]: isTextArea,
                    [styles.form__upload]: isFileUpload,
                    [styles.form__custom]: isCustomField,
                    [styles.form_field__error]: errors[name] && touched[name],
                  }),
                  placeholder,
                };
                isTextArea ? (fieldProps.as = FormFieldEnum.textarea) : (fieldProps.type = type);
                if (isCustomField) {
                  fieldProps.component = component;
                  fieldProps.componentProps = componentProps;
                }
                if (isFileUpload) {
                  fieldProps.onChange = (e: ChangeEvent<HTMLInputElement>) => {
                    const file = e.currentTarget.files?.[0];
                    if (file) {
                      setFieldValue('file', file);
                      setUploadedFile(file);
                    }
                  };
                  fieldProps.value = undefined;
                }

                return (
                  <label
                    className={cn(styles.form__label, {
                      [styles.form__label__upload]: isFileUpload,
                    })}
                    key={name}
                  >
                    {isFileUpload ? (
                      <p className={styles.form__label__helper_text}>
                        {!uploadedFile
                          ? fieldProps.placeholder
                          : t('form_fields.uploaded_file_descr', {
                              name: sliceTextLength(uploadedFile?.name),
                              size: formatBytes(uploadedFile?.size),
                            })}
                        )
                      </p>
                    ) : (
                      <></>
                    )}
                    <Field {...fieldProps} />
                  </label>
                );
              },
            )}
          </div>

          {ExtraElement ? <ExtraElement /> : <></>}
          <Button
            type="submit"
            text={text}
            className={cn(styles.form__button, buttonClassName)}
            loading={loading}
            disabled={loading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FormConstructor;
